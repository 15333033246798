import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { BiUserCircle } from 'react-icons/bi';
import { CgTimer } from 'react-icons/cg';
import { useInterval } from 'react-use';
import { coreItem } from '0_variables/coreItem';
import {
  refreshLicenseInfo,
  refreshTokens,
  setLoginThunk,
  waitForRefreshTokenJobInfoCondition,
} from '1_reduxs/actions/loginAction';
import { store } from '1_reduxs/store';
import { openVersionModal } from '1_reduxs/reducers/modalReducer';
import { AuthCheck } from '2_services/loginApi';
import {
  dashboard as MEDIA_dashboard,
  blueDashboard as MEDIA_blueDashboard,
  upload as MEDIA_upload,
  blueUpload as MEDIA_blueUpload,
  view as MEDIA_view,
  blueView as MEDIA_blueView,
  analysis as MEDIA_analysis,
  blueAnalysis as MEDIA_blueAnalysis,
  setting as MEDIA_setting,
  blueSetting as MEDIA_blueSetting,
  logout as MEDIA_logout,
} from '6_media';
import BrtnxLogo from '6_media/svg/BrtnxLogo';
import { MenuButton } from './components';
import styles from './Menu.module.scss';

export default function Menu() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { product: productName } = useParams();
  const username = sessionStorage.getItem('username');
  const pageName = location.pathname.split('/')[2];
  const productCoreItem = coreItem[productName];
  const { CTN_Mode, Cloud_Mode } = productCoreItem;
  const selectedFileID = useSelector((state) => state.control.selectedFileID);
  const productLicenseState = useSelector(
    (state) => state.license[productName],
  );
  const endDate = productLicenseState.until;
  const remainCounts = productLicenseState.count;

  const menuItems = [
    {
      enabled: true,
      name: 'Dashboard',
      unactiveIcon: MEDIA_dashboard,
      push: `/${productName}/dashboard`,
      activeIcon: MEDIA_blueDashboard,
      callBack: () => undefined,
    },
    {
      enabled: true,
      name: 'Upload',
      unactiveIcon: MEDIA_upload,
      push: `/${productName}/upload`,
      activeIcon: MEDIA_blueUpload,
      callBack: () => undefined,
    },
    {
      enabled: selectedFileID !== null,
      name: 'View',
      unactiveIcon: MEDIA_view,
      push: `/${productName}/view/${selectedFileID}`,
      activeIcon: MEDIA_blueView,
      callBack: () => undefined,
    },
  ];

  if (CTN_Mode === false) {
    menuItems.push(
      {
        enabled: selectedFileID !== null,
        name: 'Analysis',
        unactiveIcon: MEDIA_analysis,
        push: `/${productName}/analysis/${selectedFileID}`,
        activeIcon: MEDIA_blueAnalysis,
        callBack: () => undefined,
      },
      {
        enabled: true,
        name: 'Setting',
        unactiveIcon: MEDIA_setting,
        push: `/${productName}/setting`,
        activeIcon: MEDIA_blueSetting,
        callBack: () => undefined,
      },
    );
  }

  const logoutMenu = {
    enabled: true,
    name: 'Log out',
    unactiveIcon: MEDIA_logout,
    push: '/signin',
    callBack: () =>
      dispatch(
        setLoginThunk({
          username: '',
          token: '',
          expire: '',
          refreshToken: '',
          logged: false,
        }),
      ),
  };

  // token validation, license check
  useInterval(async () => {
    let canRefreshToken = true;
    let finishedTime = '';

    // 만약 이미 token 갱신 작업 중이면 완료될 때까지 대기
    if (process.env.REACT_APP_USE_REFRESH_TOKEN === 'true') {
      const { hasNewJob } = store.getState().login;

      if (hasNewJob) {
        finishedTime = (await waitForRefreshTokenJobInfoCondition(
          store,
          (state) => state.login.hasNewJob === false,
        )).finishedTime;
        if (finishedTime !== '') {
          canRefreshToken = false; // 아래에서 token 갱신 작업 필요 없음
        }
      }
    }

    const token = sessionStorage.getItem('token');

    if (process.env.REACT_APP_USE_REFRESH_TOKEN === 'true' && canRefreshToken) {
      const expire = sessionStorage.getItem('expire');

      if (token !== '' && expire !== '') { // 현재 로그인 상태인 경우
        const now = new Date();
        const expireTime = new Date(expire);

        // token 만료 5분 전이면 갱신 먼저 시도
        if (expireTime >= now && expireTime <= new Date(now.getTime() + 5 * 60 * 1000)) {
          const username = sessionStorage.getItem('username');
          const refreshToken = sessionStorage.getItem('refreshToken');
          
        if (username !== '' && refreshToken !== '') {
            dispatch(
              refreshTokens({
                username,
                refresh_token: refreshToken,
              }),
            );
          }
        }
      }
    }
    
    if (
      (process.env.REACT_APP_USE_REFRESH_TOKEN !== 'true' && token !== '')
      || (process.env.REACT_APP_USE_REFRESH_TOKEN === 'true' && token !== '' && store.getState().login.hasNewJob !== false)
    ) {
      AuthCheck();

      // update license Info
      dispatch(refreshLicenseInfo(productName));
    }
  }, 1000 * 30);

  useEffect(() => {
    const { valid, isInitailized } = productLicenseState;

    if (isInitailized && valid === false) {
      // url을 직접 치고 들어왔을때, 라이센스가 없는 경우
      alert('A product license is required to use this service.');
      history.goBack(); // move back
    }
  }, [Cloud_Mode, history, productLicenseState, productName]);

  return (
    <div
      className={`${styles['container']} ${
        pageName === 'report' ? styles.hidden : ''
      }`}
    >
      <div className={`${styles['up-items']}`}>
        <div className={`${styles['logo-container']}`}>
          <div
            className={`${styles['logo-style']}`}
            onClick={() => {
              dispatch(openVersionModal({ isOn: true }));
            }}
          >
            <BrtnxLogo />
          </div>
        </div>

        {menuItems.map((item, idx) => (
          <MenuButton key={`main-menu-${idx}`} item={item} />
        ))}
      </div>

      <div className={`${styles['down-items']}`}>
        <div className={`${styles['license-info']}`}>
          <div>
            <BiUserCircle size={'1vw'} strokeWidth={'0px'} />
            {(() => {
              if (CTN_Mode || remainCounts === 'inf') {
                return <span>{username}</span>;
              } else {
                return <span>{username + ' (' + remainCounts + ')'}</span>;
              }
            })()}
          </div>
          {endDate.trim() !== 'inf' && (
            <div>
              <CgTimer size={'1vw'} strokeWidth={'0px'} />
              <span>{endDate}</span>
            </div>
          )}
        </div>

        <MenuButton item={logoutMenu} />
      </div>
    </div>
  );
}
